import React from "react";
import { Route, Routes as DOMRoutes } from "react-router-dom";
import ProtectedRoute from "routes/protected";
import { urls } from "./urls";
import SignIn from "views/auth/signIn/SignIn";
import Dashboard from "views/admin/dashboard";
import Downloads from "views/admin/downloads";
import Audios from "views/admin/audios";
import Videos from "views/admin/videos";
import HeygenAudios from "views/admin/heygen/audios";
import HeygenVideos from "views/admin/heygen/videos";
import StreamingAvatar from "views/admin/streaming-avatar";
import Users from "views/admin/users";
import Companies from "views/admin/companies";
import SignUp from "views/auth/signUp";
import RequestAvatar from "views/admin/request-avatar/list";
import AvatarDetails from "views/admin/request-avatar/detail";
import SubmittedAvatarsList from "views/admin/submitted-avatar/list";
import SubmittedAvatarsDetail from "views/admin/submitted-avatar/detail";
import VerficationVideo from "views/admin/verification-video";
import EmptyPage from "components/404/EmptyPage";
import UserDetail from "views/admin/users/component/UserDetail";
import CompanyDetail from "views/admin/companies/component/CompanyDetail";

const Routes = () => {
  return (
    <DOMRoutes>
      <Route path={urls.signIn} element={<SignIn />} />
      <Route path={urls.signUp} element={<SignUp />} />
      <Route element={<ProtectedRoute />}>
        <Route path={urls.dashboard} element={<Dashboard />} />
        <Route path={urls.downloads} element={<Downloads />} />
        <Route path={urls.audios} element={<Audios />} />
        <Route path={urls.videos} element={<Videos />} />
        <Route path={urls.streamingAvatar} element={<StreamingAvatar />} />
        <Route path={urls.requestAvatar} element={<RequestAvatar />} />
        <Route
          path={`${urls.requestAvatar}/:avatarType`}
          element={<AvatarDetails />}
        />
        <Route
          path={urls.submittedAvatars}
          element={<SubmittedAvatarsList />}
        />
        <Route
          path={`${urls.submittedAvatars}/:avatarId`}
          element={<SubmittedAvatarsDetail />}
        />
        <Route path={urls.users} element={<Users />} />
        <Route path={`${urls.users}/:id`} element={<UserDetail/>} />
        <Route path={urls.companies} element={<Companies />} />
        <Route path={`${urls.companies}/:id`} element={<CompanyDetail/>}/>
        <Route path={urls.heygenVoices} element={<HeygenAudios />} />
        <Route path={urls.heygenVideos} element={<HeygenVideos />} />
        <Route path={urls.verificationVideo} element={<VerficationVideo />} />
      </Route>
      <Route path="*" element={<EmptyPage/>} />
    </DOMRoutes>
  );
};

export default Routes;
