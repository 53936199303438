import { Flex, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
function AuthIllustration(props) {
  const bgColorMode = useColorModeValue("rgba(255,255,255,0.3)", "#43434350");
  const { children } = props;
  let navbarBackdrop = "blur(30px)";
  let navbarFilter = "none";

  return (
    <Flex
      position="relative"
      h="90vh"
      alignItems='center'
      bg={bgColorMode}
      borderRadius="20px"
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
    >
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "80vh",
          xl: "80vh",
        }}
        w="100%"
        maxW={{ sm: "100%", xl: "50%" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {children}
      </Flex>
    </Flex>
  );
}

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
