import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: [],
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: ({ data }) => ({
        url: `/auth/sign-up`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useSignUpMutation } = authApi;
