import { Box, Button, Card, Flex, FormLabel, Input, SimpleGrid, Stack, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomTable from "components/tables/CustomTable";
import AudioCard from "components/card/AudioCard";
import { useListOfSubmittedAvatarsQuery } from "store/api/avatar";
import { useMemo } from "react";
import Loader from "components/loader/Loader";
import { useSelector } from "react-redux";
import VideoPlayer from "views/admin/dashboard/components/VideoPlayer";
import { MdOutlineEdit } from "react-icons/md";

const SubmittedAvatarDetails = () => {
  const { avatarId } = useParams();
  const [playingVideo, setPlayingVideo] = useState(null);
  const userDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = userDetails.user?.user_metadata?.is_admin;
  const { data, isLoading } = useListOfSubmittedAvatarsQuery({ isAdmin });
  const [isEditing, setIsEditing] = useState(false);
  const buttonBg = useColorModeValue(
    "rgba(0,0,0,0.1)",
    "rgba(255,255,255,0.1)"
  );
  const carbg = useColorModeValue("#fff", "#434343");
  const inputColor = useColorModeValue('#000', '#fff')

  const [csvData, setCsvData] = useState([]);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleFileFromUrl = async (fileUrl) => {
    // Extract the filename from the URL before the query parameters
    const fileName = fileUrl.split("/").pop()?.split("?")[0];
    const fileExtension = fileName?.split(".").pop()?.toLowerCase();
    console.log("POLA", fileExtension);

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        let firstColumnData = [];

        if (fileExtension === "csv") {
          const parsedData = XLSX.read(data, { type: "string" });
          const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract first column values and map to { message: value }
          firstColumnData = jsonData.map((row) => ({ message: row[0] }));
          setCsvData(firstColumnData);
        } else if (fileExtension === "xlsx") {
          const parsedData = XLSX.read(new Uint8Array(data), { type: "array" });
          const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract first column values and map to { message: value }
          firstColumnData = jsonData.map((row) => ({ message: row[0] }));
        }
        setCsvData(firstColumnData);
      };

      if (fileExtension === "csv") {
        reader.readAsText(blob);
      } else if (fileExtension === "xlsx") {
        reader.readAsArrayBuffer(blob);
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const avatarDetails = useMemo(() => {
    const findAvatar = data?.data?.find((avatar) => avatar.id === avatarId);
    return findAvatar ?? null;
  }, [data, avatarId]);
  console.log(avatarDetails);
  const handlePlay = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      document.getElementById(`video-${playingVideo}`).pause();
      document.getElementById(`video-${playingVideo}`).currentTime = 0;
    }
    setPlayingVideo(index);
  };

  useEffect(() => {
    const csvUrl = avatarDetails?.csv_asset?.url;
    if (csvUrl) handleFileFromUrl(csvUrl);
  }, [avatarDetails]);

  const columns = [
    {
      Header: "Script",
      accessor: "message",
      copy: true,
      preWrap: true,
    },
  ];

  console.log("SC", csvData);

  return (
    <Box position='relative'>
      <Button
        position="absolute"
        top={-45}
        right={5}
        bg={buttonBg}
        onClick={handleEditClick}
        p={3}
        borderRadius="full"
      >
        <MdOutlineEdit size={20} />
      </Button>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SimpleGrid mt={10}
            columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
            spacing={{ base: "40px", xl: "40px" }}
            templateColumns="repeat(3, 1fr)"
            templateRows="repeat(2, 1fr)"
            gap={4}
            height={570}
          >
            {/* gridColumn={{ sm: "1", md: "2", lg: "2" }}
            gridRow={{ sm: "1", md: "1", lg: "1" }} */}
            <Box
              gridColumn={{base:'5 / 1',md:"1 / span 3"}}
              gridRow={{base:'3/3',md:'3/3',lg:"1 / 3"}}
              order={{ base: 3, md: 1 }}
            >
              {csvData?.length ? (
                <CustomTable
                  columnsData={columns}
                  tableData={csvData ?? []}
                  totalItems={csvData?.length}
                  height="570px"
                />
              ) : (
                ""
              )}
            </Box>
            {/* gridColumn={{ sm: "1", md: "2", lg: "2" }}
            gridRow={{ sm: "1", md: "1", lg: "1" }} */}
            <Box
              gridColumn={{sm:'5 / 1',md:'5/1',lg:"4 / 4"}}
              gridRow={{ sm: "1", md: "1", lg: "1" }}
              gap={4}
              order={{ base: 1, md: 2 }}
            >
              <VideoPlayer
                videoSrc={avatarDetails?.video_assets?.[0]?.url}
                onPlay={() => handlePlay(avatarDetails?.video_assets?.[0]?.id)}
                videoId={`video-${avatarDetails?.video_assets?.[0]?.id}`}
                userName={
                  avatarDetails?.video_assets?.[0]?.name || "Unknown Avatar"
                }
              />
            </Box>
            {/* gridColumn={{ sm: "1", md: "2", lg: "2" }}
            gridRow={{ sm: "2", md: "2", lg: "2" }} */}
            <Box gridColumn={{sm:'5 / 1',md:'5/1',lg:"4 / 4"}} gridRow={{base:2,md:'2 / 3'}} order={{ base: 2, md: 3 }}>
              <AudioCard
                voice={avatarDetails?.audio_assets?.[0]}
                menuOptions={[]}
              />
            </Box>
          </SimpleGrid>
        </>
      )}
      <Card
      bg={carbg}
      borderRadius={15}
      mt={{ base: 4, md: 5 }}
      mb={{ base: 4, md: 5 }}
      p={{ base: 4, md: 5 }}
      shadow="md"
      // h="70vh"
    >
      <Box flex="2" p={3}>
          <Stack spacing={3}>
            <Flex gap={3}>
            <Box w="100%">
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="User name"
                color={inputColor}
                value={avatarDetails?.user?.name}
                disabled={!isEditing}
              />
            </Box>
            <Box w="100%">
              <FormLabel>Company</FormLabel>
              <Input
                placeholder="Company Name"
                color={inputColor}
                value={avatarDetails?.user?.company}
                disabled
              />
            </Box>
            </Flex>
            <Flex gap={3}>
            <Box w="100%">
              <FormLabel>Type</FormLabel>
              <Input
                placeholder="Avatar Type"
                color={inputColor}
                value={avatarDetails?.type}
                disabled={!isEditing}
              />
            </Box>
            <Box w="100%">
              <FormLabel>Avatar ID</FormLabel>
              <Input
                placeholder="Avatar ID"
                color={inputColor}
                value={avatarDetails?.video_assets?.[0]?.id}
                disabled
              />
            </Box>
            </Flex>
            <Flex gap={3}>
              <Box w="100%">
                <FormLabel>Created date</FormLabel>
                <Input
                  placeholder="Created Date"
                  color={inputColor}
                  disabled
                  value={new Date(avatarDetails?.created_at).toLocaleDateString()}
                />
              </Box>
              <Box w="100%">
                <FormLabel>Created Time</FormLabel>
                <Input
                  placeholder="Created Time"
                  color={inputColor}
                  disabled
                  value={new Date(avatarDetails?.created_at).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </Box>
            </Flex>
          </Stack>
        </Box>
        {isEditing && (
        <Flex justifyContent="end" gap={3}>
          <Button variant="brand" px={4}>
            Update
          </Button>
          <Button variant="ghost" px={4} onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      )}
    </Card>
    </Box>
  );
};

export default SubmittedAvatarDetails;
