import { useState, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

export function useInfiniteScroll(query, params, initialPage = 1) {
  const [page, setPage] = useState(initialPage);
  const [items, setItems] = useState([]);

  const userDetails = useSelector((state) => state.user.userDetails);

  const isAdmin = userDetails.user?.user_metadata?.is_admin;

  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const queryParams = useMemo(
    () => ({ ...params, page, isAdmin }),
    [params, page, isAdmin]
  );

  const { data, isLoading, isFetching } = query(queryParams);

  const hasMore = useMemo(
    () => data?.pagination?.current_page < data?.pagination?.total_pages,
    [data?.pagination]
  );

  useEffect(() => {
    if (Array.isArray(data?.data)) {
      setItems((prevItems) => [...prevItems, ...data.data]);
    }
  }, [data?.data]);

  useEffect(() => {
    if (inView && hasMore && !isLoading && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, hasMore, isLoading, isFetching]);

  return { items, ref, isLoading, isFetching, hasMore };
}
