import { supabase } from "superbase/auth";

export const uploadFileWithProgress = async (file, userId, bucket, folder) => {
  try {
    const filePath = `${folder}/${userId}/${file.name}`;

    // Upload the file to the user's folder inside the respective bucket
    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(filePath, file, {
        upsert: true, // This will overwrite existing files with the same name
      });

    if (error) return null;

    return data;
  } catch (error) {
    console.error("Error uploading file:", error.message);
    return null;
  }
};
