import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useJwt } from "react-jwt";
import { getAuthToken } from "utils/auth";
import AdminLayout from "layouts/admin";
import React from "react";
import { urls } from "routes/urls";
import { roles } from "routes/roles";
import { useSelector } from "react-redux";

const Protected = () => {
  const userRole = useSelector((state) => state.user.userRole);
  const location = useLocation();
  const { isExpired } = useJwt(getAuthToken() ?? "");

  const notAllowedRoutes = roles[userRole]?.notAllowedRoutes ?? [];

  if (!getAuthToken() || isExpired) return <Navigate to={urls.signIn} />;

  if (notAllowedRoutes.includes(location.pathname)) {
    return <Navigate to={urls.dashboard} />; // Redirect to dashboard or any other page if access is denied
  }

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default Protected;
