import React, { useEffect } from "react";
import { SimpleGrid, useColorModeValue, Icon, Box } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/statistics/Statistics";
import { IoMdDownload } from "react-icons/io";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { BsSoundwave } from "react-icons/bs";
import { useGetAllFilesQuery } from "store/api/avatar";
import { useDispatch, useSelector } from "react-redux";
import { saveAudioCount, saveVideoCount } from "store/slices/avatar";
import RecentAudio from "./components/RecentAudio";
import RecentVideo from "./components/RecentVideo";
import RecentUsers from "./components/RecentUsers";
import { useGetAllUsersQuery } from "store/api/user";
import HygenAssigned from "../heygen/Assigned/HygenAssigned";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { audioCount, videoCount } = useSelector((state) => state.avatar);
  const brandColor = "customThemeColor.500";
  const boxBg = useColorModeValue("#E56B2020", "whiteAlpha.100");
  const adminAuth = useSelector((state) => state.user.userDetails);
  const { data } = useGetAllUsersQuery();
  const matchedUserAuth = adminAuth?.user?.id && data?.data 
  ? data.data.find((user) => user.id === adminAuth.user.id) 
  : null;

  const { data: videosData } = useGetAllFilesQuery({
    type: "video",
  });

  const { data: audiosData } = useGetAllFilesQuery({
    type: "audio",
  });

  useEffect(() => {
    if (videosData?.data?.length) {
      dispatch(saveVideoCount(videosData?.data?.length));
    }

    if (audiosData?.data?.length) {
      dispatch(saveAudioCount(audiosData?.data?.length));
    }
  }, [videosData, audiosData, dispatch]);

  return (
    <>
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
      gap="20px"
      mb="20px"
    >
      <MiniStatistics
        startContent={
          <IconBox
            w="56px"
            h="56px"
            bg={boxBg}
            icon={
              <Icon w="32px" h="32px" as={BsSoundwave} color={brandColor} />
            }
          />
        }
        name="Recent Audios"
        value={audioCount ?? 0}
      />
      <MiniStatistics
        startContent={
          <IconBox
            w="56px"
            h="56px"
            bg={boxBg}
            icon={
              <Icon
                w="32px"
                h="32px"
                as={MdOutlineOndemandVideo}
                color={brandColor}
              />
            }
          />
        }
        name="Recent Videos"
        value={videoCount ?? 0}
        />
      <MiniStatistics
        startContent={
          <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon w="32px" h="32px" as={IoMdDownload} color={brandColor} />
          }
          />
        }
        name=" Recent Downloads"
        value={2}
        />
    </SimpleGrid>
    <SimpleGrid columns={{base:1, md:2}} gap={5}>
    <RecentAudio/>
    <RecentVideo/>
    </SimpleGrid>
    <Box display={matchedUserAuth?.is_admin === true ? 'block' : 'none'}>
    <RecentUsers/>
    </Box>
    <HygenAssigned/>
      </>
  );
};

export default Dashboard;
