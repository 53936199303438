import React from "react";
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Th,
  Thead,
  Tbody,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import { useGetAllUsersQuery } from "store/api/user";
import TruncateCell from "components/Truncate/TruncateCell";

const RecentUsers = () => {
  const { data, isLoading } = useGetAllUsersQuery();

  const tableBg = useColorModeValue("#fff", "#434343");
  const recentUsers = data?.data
    ?.slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 5);
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Company", accessor: "companies.name" },
    { Header: "Last Sign In At", accessor: "last_sign_in_at", format: "date" },
    { Header: "Created Date", accessor: "created_at", format: "date" },
    { Header: "Created Time", accessor: "created_at", format: "time" },
    { Header: "Updated At", accessor: "updated_at", format: "date" },
  ];

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!recentUsers || recentUsers.length === 0) {
    return (
      <Box p={5} bg={tableBg} borderRadius={15} textAlign="start">
        <Heading as="h2" size="lg" mb={4}>
          Recent Users
        </Heading>
        <Box textAlign="start">No data available</Box>
      </Box>
    );
  }

  return (
    <Box p={5} bg={tableBg} borderRadius={15} mt={5}>
      <Heading as="h2" size="lg" mb={4}>
        Recent Users
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={index}>{column.Header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {recentUsers.map((user, index) => (
              <Tr key={index}>
                {columns.map((column, colIndex) => (
                  <TruncateCell
                    key={colIndex}
                    text={
                      column.format === "date"
                        ? new Date(user[column.accessor]).toLocaleDateString()
                        : column.format === "time"
                        ? new Date(user[column.accessor]).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : column.accessor.includes(".")
                        ? column.accessor.split('.').reduce((o, i) => o?.[i], user)
                        : user[column.accessor]
                    }
                    length={25}
                  />
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecentUsers;