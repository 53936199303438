import React from "react";
import { Box } from "@chakra-ui/react";
import './style.css'

const Loader = () => {

  return (
    <Box w='100%' display='flex' justifyContent='center' alignItems='center' height='100%'>
        <Box width="6em" height="6em">
    <Box
      as="svg"
      viewBox="0 0 240 240"
      height="140"
      width="140"
    >
      <Box
        as="circle"
        cx="120"
        cy="120"
        r="105"
        fill="none"
        stroke="#E56B20"
        strokeWidth="20"
        strokeLinecap="round"
        sx={{
          animation: "ringA 2s linear infinite",
        }}
      />
      <Box
        as="circle"
        cx="120"
        cy="120"
        r="35"
        fill="none"
        stroke="#E56B2050"
        strokeWidth="20"
        strokeLinecap="round"
        sx={{
          animation: "ringB 2s linear infinite",
        }}
      />
      <Box
        as="circle"
        cx="85"
        cy="120"
        r="70"
        fill="none"
        stroke="#E56B2075"
        strokeWidth="20"
        strokeLinecap="round"
        sx={{
          animation: "ringC 2s linear infinite",
        }}
      />
      <Box
        as="circle"
        cx="155"
        cy="120"
        r="70"
        fill="none"
        stroke="#E56B20"
        strokeWidth="20"
        strokeLinecap="round"
        sx={{
          animation: "ringD 2s linear infinite",
        }}
      />
    </Box>
  </Box>
    </Box>
  );
};

export default Loader;
