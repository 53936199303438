import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const heygenApi = createApi({
  reducerPath: "heygenApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: [],
  endpoints: (builder) => ({
    getAvatars: builder.query({
      query: ({ page }) => `/admin/heygen-assets?type=video&page=${page}`,
    }),
    getVoices: builder.query({
      query: ({ page }) => `/admin/heygen-assets?type=audio&page=${page}`,
    }),
  }),
});

export const { useGetAvatarsQuery, useGetVoicesQuery } = heygenApi;
