import { Box, Heading, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React, { useState } from "react";
import VideoPlayer from "./VideoPlayer";
import { useGetAllFilesQuery } from "store/api/avatar";

const RecentVideo = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const { data, isLoading } = useGetAllFilesQuery({ type: "video" });
  const tableBg = useColorModeValue("#fff", "#434343");
  const avatars = data?.data || [];
  const recentAvatars = avatars.slice(0, 4);
  // const headers = avatars.length > 0 ? Object.keys(avatars[0]) : [];
  const avatarsWithRefs = recentAvatars?.map((avatar) => ({
    ...avatar,
    ref: React.createRef(),
  }));
  const handlePlay = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      document.getElementById(`video-${playingVideo}`).pause();
      document.getElementById(`video-${playingVideo}`).currentTime = 0;
    }
    setPlayingVideo(index);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!avatarsWithRefs || avatarsWithRefs.length === 0) {
    return (
        <Box p={5} bg={tableBg} borderRadius={15} textAlign="start">
            <Heading as="h2" size="lg" mb={4}>Recent Videos</Heading>
            <Box textAlign='start'>No data available</Box>
        </Box>
    );
}

  return (
    <Box p={5} bg={tableBg} borderRadius={15}>
      <Heading as="h2" size="lg" mb={4}>
        Recent Videos
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap={3}>
        {avatarsWithRefs?.map((avatar, index) => (
          <VideoPlayer
            videoSrc={avatar?.url}
            onPlay={() => handlePlay(index)}
            videoId={`video-${index}`}
            userName={avatar?.name || "Unknown Avatar"}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default RecentVideo;
