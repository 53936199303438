import {
  Box,
  Button,
  Card,
  Flex,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React, { useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useGetAllCompaniesQuery } from "store/api/company";

const CompanyDetail = () => {
  const { id } = useParams();
  const carbg = useColorModeValue("#fff", "#434343");
  const { data, isLoading, isFetching } = useGetAllCompaniesQuery();
  const company = data?.data?.find((comp) => comp.id === id);
  const [isEditing, setIsEditing] = useState(false);
  const buttonBg = useColorModeValue(
    "rgba(0,0,0,0.1)",
    "rgba(255,255,255,0.1)"
  );
  const inputColor = useColorModeValue('#000', '#fff')
  
  if (isLoading || isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!company) {
    return <div>Company not found</div>;
  }
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <Card
      bg={carbg}
      borderRadius={15}
      mt={{ base: 4, md: 5 }}
      mb={{ base: 4, md: 5 }}
      p={{ base: 4, md: 5 }}
      shadow="md"
      // h="70vh"
    >
      <Button
        position="absolute"
        top={5}
        right={5}
        bg={buttonBg}
        onClick={handleEditClick}
        p={3}
        borderRadius="full"
      >
        <MdOutlineEdit size={20} />
      </Button>
      <Flex
        direction={{ base: "column", md: "row" }}
        spacing={5}
        alignItems="center"
        mt={5}
      >
        <Box
          flex="1"
          borderRight={{ base: "none", md: "1px solid lightgray" }}
          p={3}
          textAlign="center"
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width="150px"
            height="150px"
            bg="black"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
          >
            <FaRegBuilding color="white" size="60px" />
          </Box>
          <Text fontWeight="bold" mt={3} fontSize={{ base: "lg", md: "xl" }}>
            {company?.name}
          </Text>
          <Text color="gray.500" fontSize={{ base: "sm", md: "md" }}>
            {company?.email}
          </Text>
        </Box>

        <Box flex="2" p={3}>
          <Stack spacing={3}>
            <Box w="100%">
              <FormLabel>Company Name</FormLabel>
              <Input
                placeholder="Company name"
                color={inputColor}
                value={company?.name}
                disabled={!isEditing}
              />
            </Box>
            <Box w="100%">
              <FormLabel>Company ID</FormLabel>
              <Input
                placeholder="Company ID"
                color={inputColor}
                value={company?.id}
                disabled
              />
            </Box>
            <Flex gap={3}>
              <Box w="100%">
                <FormLabel>Created date</FormLabel>
                <Input
                  placeholder="Created Date"
                  color={inputColor}
                  disabled
                  value={new Date(company?.created_at).toLocaleDateString()}
                />
              </Box>
              <Box w="100%">
                <FormLabel>Created Time</FormLabel>
                <Input
                  placeholder="Created Time"
                  color={inputColor}
                  disabled
                  value={new Date(company?.created_at).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Flex>
      <Box flex="1" p={3}>
        <Text fontSize="xl" mb={3} fontWeight={700}>
          Users Details
        </Text>
        <SimpleGrid columns={{ base: 1, md: 1 }} gap={5}>
          {company?.users?.length > 0 ? (
            company?.users?.map((user) => (
              <Stack spacing={3} key={user.id}>
                <Flex gap={3}>
                  <Box w="100%">
                    <FormLabel>Name</FormLabel>
                    <Input
                      placeholder="User name"
                      color={inputColor}
                      disabled
                      value={user?.name}
                    />
                  </Box>
                  <Box w="100%">
                    <FormLabel>Email</FormLabel>
                    <Input
                      placeholder="Company ID"
                      color={inputColor}
                      disabled
                      value={user?.email}
                    />
                  </Box>
                </Flex>
                <Flex gap={3}>
                  <Box w="100%">
                    <FormLabel>ID</FormLabel>
                    <Input
                      placeholder="User name"
                      color={inputColor}
                      disabled
                      value={user?.id}
                    />
                  </Box>
                  <Box w="100%">
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      placeholder="Company ID"
                      color={inputColor}
                      disabled
                      value={user?.phone === !null ? user?.phone : "No number"}
                    />
                  </Box>
                </Flex>
                <Flex gap={3}>
                  <Box w="100%">
                    <FormLabel>Created Date</FormLabel>
                    <Input
                      placeholder="Created Date"
                      color={inputColor}
                      disabled
                      value={new Date(user?.created_at).toLocaleDateString()}
                    />
                  </Box>
                  <Box w="100%">
                    <FormLabel>Created Time</FormLabel>
                    <Input
                      placeholder="Created Time"
                      color={inputColor}
                      disabled
                      value={new Date(user?.created_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    />
                  </Box>
                </Flex>
                <Flex gap={3}>
                  <Box w="100%">
                    <FormLabel>Last Sign In Date</FormLabel>
                    <Input
                      placeholder="last sign in date"
                      color={inputColor}
                      disabled
                      value={new Date(
                        user?.last_sign_in_at
                      ).toLocaleDateString()}
                    />
                  </Box>
                  <Box w="100%">
                    <FormLabel>Last Sign In Time</FormLabel>
                    <Input
                      placeholder="Created Time"
                      color={inputColor}
                      disabled
                      value={new Date(user?.last_sign_in_at).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    />
                  </Box>
                </Flex>
              </Stack>
            ))
          ) : (
            <Text>No users available</Text>
          )}
        </SimpleGrid>
      </Box>

      {isEditing && (
        <Flex justifyContent="end" gap={3}>
          <Button variant="brand" px={4}>
            Update
          </Button>
          <Button variant="ghost" px={4} onClick={handleCancelClick}>
            Cancel
          </Button>
        </Flex>
      )}
    </Card>
  );
};

export default CompanyDetail;
