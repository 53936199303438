import {
  Button,
  Flex,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Upload from "components/upload/Upload";
import AudioCard from "components/card/AudioCard";
import Loader from "components/loader/Loader";
import { useGetVoicesQuery } from "store/api/heygen";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

const HeygenAudios = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    items: audiosData,
    ref,
    isLoading,
    isFetching,
    hasMore,
  } = useInfiniteScroll(useGetVoicesQuery);

  return (
    <Flex direction="column">
      <Button
        variant="brand"
        marginLeft="auto"
        fontWeight="500"
        mb={5}
        px={5}
        onClick={() => setIsModalOpen(true)}
      >
        Add new
      </Button>

      {!isLoading ? (
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          spacing={{ base: "10px", xl: "10px" }}
        >
          {audiosData?.map((voice) => (
            <AudioCard voice={voice} key={voice?.voice_id} />
          ))}
        </SimpleGrid>
      ) : (
        <Box height="50vh" w="100%">
          <Loader />
        </Box>
      )}
      {isFetching && <Loader />}
      {hasMore && !isLoading && <Box ref={ref} style={{ height: "20px" }} />}
      <Modal
        size="xl"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent minW={{ sm: "95vw", xl: "50vw" }}>
          <ModalHeader>Add new audio</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Upload
              description="MP3, WAV, AAC, FLAC, and OGG files are allowed"
              accept="audio"
              w="100%"
              h="300px"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default HeygenAudios;
