import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalCloseButton,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Upload from "components/upload/Upload";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetVerificationVideoQuery } from "store/api/user";

const VerificationVideo = () => {
  // const toast = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const textColor = useColorModeValue('#00000075', '#cecece')
  const { data, isLoading } = useGetVerificationVideoQuery();

  const modalBackground = useColorModeValue("lightModeBg.100", "darkModeBg.100");

  return (
    <Flex direction="column">
      {!isLoading ? (
        data?.data?.verification_video_path ? (
          <>
            <Button
              variant="brand"
              marginLeft="auto"
              fontWeight="500"
              mb={5}
              px={5}
              onClick={() => setIsModalOpen(true)}
            >
              Update video
            </Button>
            <VideoCard
              video={{ url: data.data.verification_video_url }}
            />
          </>
        ) : (
          <Flex w="100%" gap="4rem" flexDir="column">
            <p style={{fontWeight:700}}>
              <span style={{color:textColor}}>Please upload verfication video of yourself saying</span><br /> I, (name), allow HeyGen to
              use the footage of me to build a HeyGen Avatar for use on the HeyGen platform.
            </p>
            <Button
              variant="brand"
              marginX="auto"
              fontWeight="500"
              mb={5}
              px={5}
              onClick={() => setIsModalOpen(true)}
            >
              Upload video
            </Button>
          </Flex>
        )
      ) : (
        <Box height="50vh" w="100%">
          <Loader />
        </Box>
      )}
      <Modal variant="brand" size="xl" isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
        <ModalContent minW={{ sm: "95vw", xl: "50vw" }} bg={modalBackground} borderRadius={15}>
          <ModalHeader pb={0}>Add Verification video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Upload
              bucket="verification_videos"
              setIsModalOpen={setIsModalOpen}
              description="MP4, AVI, MOV, MKV, and WEBM files are allowed"
              accept="video"
              w="100%"
              h="300px"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default VerificationVideo;
