import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: null,
  isAdmin: false,
  userRole: "user",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.isAdmin = !!action.payload?.user?.user_metadata?.is_admin;
      state.userRole = !!action.payload.user?.user_metadata?.is_admin
        ? "admin"
        : "user";
    },
  },
});

export const { saveUserDetails } = userSlice.actions;

export default userSlice.reducer;
