import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Information from "components/Information/Information";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdUpload } from "react-icons/md";
import { fileTypes } from "constants/fileTypes";
import { bytesToMB } from "utils/bytesToMB";
import Dropzone from "./Dropzone";
import { uploadFileWithProgress } from "superbase/apis";
import { useSelector } from "react-redux";
import { fileUpload } from "store/constants";
import { useUploadFileMutation } from "store/api/avatar";
import { useUpdateVerificationVideoMutation } from "store/api/user";
import Loader from "components/loader/Loader";

export default function Upload(props) {
  const {
    title,
    setIsModalOpen,
    used,
    total,
    accept,
    bucket,
    description,
    uploadIcon,
    setSelectedFiles,
    hideUpload,
    ...rest
  } = props;
  const toast = useToast();
  const userDetails = useSelector((state) => state.user.userDetails);
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("black.900", "white.300");

  const [selectedFile, setSelectedFile] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState(0);

  const [uploadFile, { isLoading, isFetching, isSuccess }]=
    useUploadFileMutation();

  const [updateVerificationVideo, { isLoading: isUpdateLoading, isFetching: isUpdateFetching, isSuccess: isUpdateSuccess }] =
    useUpdateVerificationVideoMutation();

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      setIsModalOpen(false);
      toast({
        title: "File has been uploaded successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, setIsModalOpen, toast, isUpdateSuccess]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      let fileData = {
        file,
        path: null,
        preview: URL.createObjectURL(file),
        sizeMB: bytesToMB(file.size),
      };
      setSelectedFile(fileData);

      if (setSelectedFiles)
        setSelectedFiles?.((prevState) => ({
          ...prevState,
          [accept]: fileData,
        }));
    },
    [setSelectedFile, setSelectedFiles, accept]
  );

  const handleUpload = async () => {
    setLoadingFile(true);
    const upload = await uploadFileWithProgress(
      selectedFile.file,
      userDetails?.user?.id,
      bucket ?? fileUpload[`${accept}`],
      userDetails?.user?.company_id ?? "dummy-co"
    );
    if (!upload) {
      setHasError(true);
      toast({
        title: "Error uploading file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    const body = {
      path: upload?.path,
      type: accept,
      bucket: bucket ?? `${accept}-files`,
      name: `${accept}-${new Date().getTime()}`,
    };

    if(bucket === "verification_videos") {
      await updateVerificationVideo({body})
    } else {
      await uploadFile({ body });
    }

    setLoadingFile(false);
  };

  const fileType = useMemo(() => fileTypes[accept], [accept]);

  return (
    <Card {...rest} mb="20px" align="center" p="20px" minH={"400px"}>
      <Flex h="100%" direction={{ base: "column" }}>
        {!loadingFile ? (
          <Dropzone
            accept={fileType}
            onDrop={onDrop}
            content={
              !selectedFile || hasError ? (
                <Box>
                  <Icon
                    as={uploadIcon ?? MdUpload}
                    w="80px"
                    h="80px"
                    color={brandColor}
                    mb="5px"
                  />
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      {title ?? "Upload Files"}
                    </Text>
                  </Flex>
                  <Text fontSize="sm" fontWeight="500" color={textColor}>
                    {description}
                  </Text>
                </Box>
              ) : (
                <Flex direction="column" gap="10px">
                  <Information
                    bg={""}
                    title="File name"
                    value={selectedFile?.file?.name}
                  />
                  <Information
                    bg={""}
                    title="Size"
                    value={selectedFile?.sizeMB}
                  />
                  <Information
                    bg={""}
                    title="Type"
                    value={selectedFile?.file?.type}
                  />
                </Flex>
              )
            }
          />
        ) : (
          <Box height="30vh" w="100%">
            <Loader />
          </Box>
        )}
      </Flex>
      {props.children} {/* Render children here */}
      {!!selectedFile && !hideUpload && (
        <Flex margin="auto">
          <Button
            me="100%"
            w="140px"
            minW="140px"
            mt={{ base: "20px" }}
            variant="brand"
            fontWeight="500"
            onClick={handleUpload}
            isLoading={isLoading || isFetching || isUpdateLoading || isUpdateFetching}
            isDisabled={isLoading || isFetching || isUpdateLoading || isUpdateFetching || loadingFile}
          >
            Upload
          </Button>
        </Flex>
      )}
    </Card>
  );
}
