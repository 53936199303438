import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const avatarApi = createApi({
  reducerPath: "avatarApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Assets", "Avatar"],
  endpoints: (builder) => ({
    getDownloadsFiles: builder.query({
      query: ({ page }) => `/downloads?page=${page}`,
      providesTags: ["Assets"],
    }),
    getAllFiles: builder.query({
      query: ({ type, isAdmin, page }) =>
        `${isAdmin ? "/admin" : ""}/assets?type=${type}&page=${page}`,
      providesTags: ["Assets"],
    }),
    uploadFile: builder.mutation({
      query: ({ body }) => ({
        url: `/assets`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Assets"],
    }),
    deleteFile: builder.mutation({
      query: ({ id }) => ({
        url: `/assets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Assets"],
    }),
    avatarRequest: builder.mutation({
      query: ({ body }) => ({
        url: `/avatar-requests`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Avatar"],
    }),
    listOfSubmittedAvatars: builder.query({
      query: ({ isAdmin }) => `${isAdmin ? "/admin" : ""}/avatar-requests`,
      providesTags: ["Avatar"],
    }),
  }),
});

export const {
  useGetDownloadsFilesQuery,
  useListOfSubmittedAvatarsQuery,
  useUploadFileMutation,
  useGetAllFilesQuery,
  useDeleteFileMutation,
  useAvatarRequestMutation,
} = avatarApi;
