import { Box, Button, Text, VStack, keyframes } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa6';

const slideOut = keyframes`
  from { transform: translateY(0); opacity: 1; }
  to { transform: translateY(100%); opacity: 0; }
`;

const VideoPlayer = ({ videoSrc, userName, uploader, onPlay, videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const togglePlayPause = () => {
    const videoElement = document.getElementById(videoId);
    if (videoElement) {
      if (isPlaying) {
        videoElement.pause();
      } else {
        videoElement.play();
        videoElement.controls = true; 
        setShowDetails(false); 
      }
      setIsPlaying(!isPlaying);
      onPlay(); 
    }
  };

  const handleVideoEnd = () => {
    const videoElement = document.getElementById(videoId);
    if (videoElement) {
      videoElement.currentTime = 0;
      videoElement.controls = false;
    }
    setIsPlaying(false);
    setShowDetails(true);
  };

  return (
    <Box
      position="relative"
      width="100%"
      maxW="600px"
      mx="auto"
      height='100%'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        as="video"
        src={videoSrc}
        id={videoId} 
        width="100%"
        height="auto"
        borderRadius="md"
        aspectRatio={3.4/2}
        objectFit="contain"
        controls={false}
        onEnded={handleVideoEnd}
      />
      {(isHovered || !isPlaying) && (
        <Button
          onClick={togglePlayPause}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          colorScheme="orange"
          bg="#E56B20"
          borderRadius="full"
          opacity={isHovered || !isPlaying ? 1 : 0}
          _hover={{ opacity: 1 }}
          zIndex={2}
        >
          {isPlaying ? <FaPause color='white' /> : <FaPlay color='white' />}
        </Button>
      )}
      <VStack
        position="absolute"
        bottom={0}
        width="100%"
        p={4}
        background="linear-gradient(to top, rgba(0, 0, 0, 1.8), transparent)"
        borderBottomRadius="md"
        color="white"
        spacing={1}
        align="start"
        animation={showDetails ? undefined : `${slideOut} 0.5s forwards`}
      >
        <Text fontSize="lg" fontWeight={600}>{userName}</Text>
      </VStack>
    </Box>
  );
};

export default VideoPlayer;
