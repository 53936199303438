import React from "react";
import { useGetAllFilesQuery } from "store/api/avatar";
import {
  Box,
  Heading,
  useColorModeValue,
  SimpleGrid,
  Flex,
  Text,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";

const RecentAudio = () => {
  const { data, isLoading } = useGetAllFilesQuery({ type: "audio" });
  const tableBg = useColorModeValue("#fff", "#434343");
  // const headersToExclude = ['metadata','heygen_id','preview_url', 'url','supabase_bucket','is_processed','storage_path','signed_url','id','name','user_id'];
  // const headers = data?.data?.[0] ?
  //     Object.keys(data.data[0]).filter(header => !headersToExclude.includes(header)) : [];
  const recentAudioFiles = data?.data
    ?.slice()
    .sort((a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at))
    .slice(0, 5);
  const cardBg = useColorModeValue("#e9e9e9", "#00000050");

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!recentAudioFiles || recentAudioFiles.length === 0) {
    return (
        <Box p={5} bg={tableBg} borderRadius={15} textAlign="start">
            <Heading as="h2" size="lg" mb={4}>Recent Audio</Heading>
            <Box textAlign='start'>No data available</Box>
        </Box>
    );
}

  return (
    <Box p={5} bg={tableBg} borderRadius={15}>
      <Heading as="h2" size="lg" mb={4}>
        Recent Audio
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap={3}>
        {recentAudioFiles?.map((file) => (
          <Box p={5} bg={cardBg} shadow="lg" borderRadius={15}>
            <Flex gap={3} alignItems="center" mb={3}>
              <Box p="5px 12px" bg="#E56B20" color="white" borderRadius="100%">
                A
              </Box>
              <Text>{file?.name}</Text>
            </Flex>
            <audio src={file?.signed_url} controls style={{ width: "100%" }} />
            <Flex gap={3} flexWrap="wrap" mt={3}>
              <Text>Type: </Text>
              <Text>{file.type}</Text>
            </Flex>
            <Flex gap={3} flexWrap="wrap" mt={3}>
              <Text>Uploaded At: </Text>
              <Text>{new Date(file?.uploaded_at).toLocaleDateString()}</Text>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default RecentAudio;
