import React, { useCallback, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import DefaultAuth from "layouts/auth";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { urls } from "routes/urls";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { useSignUpMutation } from "store/api/auth";

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const textColor = useColorModeValue("navy.700", "white");
  const SignUpButton = useColorModeValue("#000", "#3C3C3C");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("black.900", "red.400");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const placeholderColor = 'gray';
  const borderColor = useColorModeValue("black", "inherit");
  const [userSignUp, { isLoading }] = useSignUpMutation();

  const handleClick = () => setShow(!show);

  const handleSignUp = useCallback(
    async (data) => {
      userSignUp({ data }).then((result) => {
        if (result.error) {
          toast({
            title: "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "SignUp successful",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setTimeout(() => {
            navigate(urls.signIn);
          }, 1000);
        }
      });
    },
    [userSignUp, navigate, toast]
  );

  useEffect(() => {
    const handleKeyDown = (ev) => {
      if (ev.key === "Enter") {
        handleSubmit(handleSignUp)(ev);
      }
    };

    // Add event listener for the 'Enter' key
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSubmit, handleSignUp]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box textAlign="center" w="full">
          <Heading size="md" mb={5}>
            Avatars AskmeAI
          </Heading>
        </Box>
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign Up
          </Heading>
          <Text
            mb="5px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Create your account!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl as="form" onSubmit={handleSubmit(handleSignUp)}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired
              {...register("email")}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@simmmple.com"
              mb="14px"
              fontWeight="500"
              _placeholder={{ color: placeholderColor }}
              color={placeholderColor}
              borderColor={borderColor}
              size="lg"
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired
                {...register("password")}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="14px"
                size="lg"
                type={show ? "text" : "password"}
                _placeholder={{ color: placeholderColor }}
              color={placeholderColor}
              borderColor={borderColor}
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={placeholderColor}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Company<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired
              {...register("company_name")}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="e.g. Google"
              mb="14px"
              fontWeight="500"
              _placeholder={{ color: placeholderColor }}
              color={placeholderColor}
              borderColor={borderColor}
              size="lg"
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Phone<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired
              {...register("phone")}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="e.g. +1-212-456-7890"
              mb="14px"
              fontWeight="500"
              _placeholder={{ color: placeholderColor }}
              color={placeholderColor}
              borderColor={borderColor}
              size="lg"
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Username<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired
              {...register("username")}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="e.g. Mike Tyson"
              mb="14px"
              fontWeight="500"
              _placeholder={{ color: placeholderColor }}
              color={placeholderColor}
              borderColor={borderColor}
              size="lg"
            />
            <Button
              fontSize="sm"
              color="white"
              variant="brand"
              bg={SignUpButton}
              fontWeight="500"
              w="100%"
              h="50"
              mb="14px"
              type="submit"
              isLoading={isSubmitting || isLoading}
            >
              Sign Up
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already have an account?
              <NavLink to={urls.signIn}>
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Login
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
        <FixedPlugin />
      </Flex>
    </DefaultAuth>
  );
};

export default SignUp;
