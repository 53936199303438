export const fileTypes = {
  audio: {
    "audio/mpeg": [".mp3"], // MP3
    "audio/wav": [".wav"], // WAV
    "audio/ogg": [".ogg"], // OGG
    "audio/flac": [".flac"], // FLAC
    "audio/aac": [".aac"], // AAC
  },
  video: {
    "video/mp4": [".mp4"], // MP4
    "video/webm": [".webm"], // WebM
    "video/ogg": [".ogg"], // OGG
    "video/avi": [".avi"], // AVI
    "video/mkv": [".mkv"], // MKV
  },
  csv: {
    "text/csv": [".csv"], // CSV
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ], // XLSX
  },
};
