import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useToast,
  Text,
  Link,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import { uploadFileWithProgress } from "superbase/apis";
import Upload from "components/upload/Upload";
import { fileUpload } from "store/constants";
import { FaFileVideo } from "react-icons/fa";
import { MdAudioFile } from "react-icons/md";
import { FaFileCsv } from "react-icons/fa";
import React, { useState } from "react";
import { useAvatarRequestMutation } from "store/api/avatar";
import { useNavigate, useParams } from "react-router-dom";
import { urls } from "routes/urls";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomTable from "components/tables/CustomTable";
import { useMemo } from "react";

const AvatarDetails = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const navigate = useNavigate();
  const toast = useToast();
  const { avatarType } = useParams();

  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    audio: {},
    video: {},
    csv: {},
  });

  const [avatarRequest, { isLoading }] = useAvatarRequestMutation();

  useEffect(() => {
    const file = selectedFiles?.csv?.file;
    if (file) {
      const reader = new FileReader();
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      reader.onload = (e) => {
        const data = e.target?.result;
        let firstColumnData = [];

        if (fileExtension === "csv") {
          const parsedData = XLSX.read(data, { type: "string" });
          const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract first column values and map to { message: value }
          firstColumnData = jsonData.map((row) => ({ message: row[0] }));
          setCsvData(firstColumnData);
        } else if (fileExtension === "xlsx") {
          const parsedData = XLSX.read(new Uint8Array(data), { type: "array" });
          const sheet = parsedData.Sheets[parsedData.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Extract first column values and map to { message: value }
          firstColumnData = jsonData.map((row) => ({ message: row[0] }));
          setCsvData(firstColumnData);
        }
      };

      if (fileExtension === "csv") {
        reader.readAsText(file);
      } else if (fileExtension === "xlsx") {
        reader.readAsArrayBuffer(file);
      }
    }
  }, [selectedFiles.csv]);

  const columns = [
    {
      Header: "Script",
      accessor: "message",
      copy: true,
      preWrap: true,
      divider: true,
    },
  ];

  const handleUpload = async () => {
    setLoading(true);
    const uploadVideo = await uploadFileWithProgress(
      selectedFiles.video.file,
      userDetails?.user?.id,
      fileUpload[`video`],
      "dummy-co"
    );
    const uploadAudio = await uploadFileWithProgress(
      selectedFiles.audio.file,
      userDetails?.user?.id,
      fileUpload[`audio`],
      "dummy-co"
    );
    const uploadCSV = await uploadFileWithProgress(
      selectedFiles.csv.file,
      userDetails?.user?.id,
      fileUpload[`csv`],
      "dummy-co"
    );

    if (!uploadVideo || !uploadAudio || !uploadCSV) {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const body = {
      assets: [
        {
          name: `video-${new Date().getTime()}`,
          storage_path: uploadVideo.path,
          supabase_bucket: "video-files",
          type: "video",
        },
        {
          name: `audio-${new Date().getTime()}`,
          storage_path: uploadAudio.path,
          supabase_bucket: "audio-files",
          type: "audio",
        },
        {
          name: `csv-${new Date().getTime()}`,
          storage_path: uploadCSV.path,
          supabase_bucket: "csv-files",
          type: "csv",
        },
      ],
      avatar_type: avatarType,
    };
    avatarRequest({ body }).then((result) => {
      if (result.error) {
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Avatar request has been submitted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate(urls.submittedAvatars);
      }
    });
    setLoading(false);
  };

  const isDisabled = useMemo(
    () =>
      selectedFiles.video ||
      selectedFiles.audio ||
      selectedFiles.csv ||
      isLoading ||
      loading,
    [selectedFiles, isLoading, loading]
  );
  return (
    <Box>
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
        spacing={{ base: "40px", xl: "40px" }}
      >
        <Upload
          setSelectedFiles={setSelectedFiles}
          hideUpload={true}
          title="Upload Video Files"
          uploadIcon={FaFileVideo}
          description="MP4, AVI, MOV, MKV, and WEBM files are allowed"
          accept="video"
          w="100%"
          h="300px"
        >
          <Text mt={2} color="gray.500" fontWeight="bold">
            A specific set of guidelines must be followed to record a video. For
            more details, refer to this
            <Link
              color="teal.500"
              href="https://www.storydoc.com/8ae74e38d8d57219485587968a17d9e7/04ca9492-42be-4b86-b450-33a542f2199b/6695eab3d05b869eb22e463f"
              isExternal
            >
              &nbsp;guide
            </Link>
            .
          </Text>
        </Upload>
        <Upload
          setSelectedFiles={setSelectedFiles}
          hideUpload={true}
          title="Upload Audio Files"
          uploadIcon={MdAudioFile}
          description="MP3, WAV, AAC, FLAC, and OGG files are allowed"
          accept="audio"
          w="100%"
          h="300px"
        >
          <Text mt={2} color="gray.500" fontWeight="bold">
            A specific set of guidelines must be followed to record a Audios.
            For more details, refer to this
            <Link
              color="teal.500"
              href="https://www.storydoc.com/8ae74e38d8d57219485587968a17d9e7/91802fea-c325-43e0-ae15-6b7ab89dc4a0/6698349f58a9ed779f8a1321"
              isExternal
            >
              &nbsp;guide
            </Link>
            .
          </Text>
        </Upload>
        <Upload
          setSelectedFiles={setSelectedFiles}
          hideUpload={true}
          title="Upload CSV File"
          uploadIcon={FaFileCsv}
          description="Only CSV files are allowed"
          accept="csv"
          w="100%"
          h="300px"
        >
          <Text mt={2} color="gray.500" fontWeight="bold">
            A specific set of guidelines must be followed for the csv file
            format. For more details, see
            <Link
              color="teal.500"
              href="https://jutouctdpjmetcyqorux.supabase.co/storage/v1/object/public/public_data/csv-samples/Dean%20example%20script%20.csv"
              isExternal
            >
              &nbsp;this&nbsp;
            </Link>{" "}
            sample csv.
          </Text>
        </Upload>
        <Box>
          {csvData?.length ? (
            <CustomTable
              columnsData={columns}
              tableData={csvData ?? []}
              totalItems={csvData?.length}
              height="400px"
            />
          ) : (
            ""
          )}
        </Box>
      </SimpleGrid>

      <Flex direction="row" justifyContent="center">
        <Button
          minW="140px"
          mt={{ base: "20px" }}
          variant="brand"
          fontWeight="500"
          onClick={handleUpload}
          disbaled={isDisabled}
          isLoading={isLoading || loading}
        >
          Upload
        </Button>
      </Flex>
    </Box>
  );
};

export default AvatarDetails;
