import { Box, SimpleGrid } from "@chakra-ui/react";
import { BsEyeFill } from "react-icons/bs";
import React, { useState } from "react";
import CustomTable from "components/tables/CustomTable";
import Loader from "components/loader/Loader";
import { useListOfSubmittedAvatarsQuery } from "store/api/avatar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SubmittedAvatar = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 50;

  const userDetails = useSelector((state) => state.user.userDetails);
  const isAdmin = userDetails.user?.user_metadata?.is_admin;
  const { data, isLoading, isFetching } = useListOfSubmittedAvatarsQuery({ isAdmin });

  const columns = [
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    ...(isAdmin
      ? [
          {
            Header: "Name",
            accessor: "user.name",
          },
          {
            Header: "Company",
            accessor: "user.company",
          },
        ]
      : []),
    {
      Header: "Audios",
      accessor: "audio_assets[0].url",
      link: true,
      linkText: "Audio Link",
    },
    {
      Header: "Videos",
      accessor: "video_assets[0].url",
      link: true,
      linkText: "Video Link",
    },
    {
      Header: "CSV",
      accessor: "csv_asset.url",
      link: true,
      linkText: "CSV Link",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        return (
          <Box ml={5}>
            <BsEyeFill size={20} cursor="pointer" onClick={() => navigate(row.original.id)} />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: "10px", xl: "10px" }}>
        {data?.data?.length ? (
          <CustomTable
            columnsData={columns}
            tableData={data?.data ?? []}
            currentPage={page}
            setCurrentPage={setPage}
            totalItems={data?.totalItems}
            pageSize={pageSize}
            isLoading={isLoading || isFetching}
          />
        ) : (
          <Loader />
        )}
      </SimpleGrid>
    </Box>
  );
};

export default SubmittedAvatar;
