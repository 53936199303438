import { SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import AssignedAudio from './AssignedAudio'
import AssignedVideo from './AssignedVideo'

const HygenAssigned = () => {    
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }} gap="20px" mt={5}>
        <AssignedAudio/>
        <AssignedVideo/>
    </SimpleGrid>
  )
}

export default HygenAssigned