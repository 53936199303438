import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const EmptyPage = () => {
  return (
    <Box 
          fontFamily="Poppins"
          height='90vh' 
          bg="#434343" 
          p="1em" 
          overflow="hidden" 
          position="relative"
          borderRadius={15}
        >
          <Box position="relative" w="100%" h="100%" userSelect="none">
            <Text
              id="visual"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%) rotate(-25deg)"
              fontFamily="Poppins"
              fontSize={{base:'30vmax', md:'50vmax'}}
              color="#282828"
              letterSpacing="0.025em"
              m="0"
              fontWeight={600}
            >
              404
            </Text>
          </Box>
    
          <Text
            color="#dadada"
            fontSize="calc(1em + 3vmin)"
            position="fixed"
            bottom="3rem"
            right="4rem"
            m="0"
            textAlign="right"
            w={{ base: '70%', md: '50%', lg: '30%', xl: '25%' }}
          >
            The page you’re looking for does not exist.
          </Text>
        </Box>
  )
}

export default EmptyPage