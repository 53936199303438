import { Box } from '@chakra-ui/react';
import Loader from 'components/loader/Loader';
import React, { useState } from 'react';

const StreamingAvatar = () => {
  const [loading, setLoading] = useState(true); 
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="685px"
      width="100%"
      borderRadius="10px"
      bg="gray.50"
      overflow="hidden"
    >
      {loading && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          bg="rgba(255, 255, 255, 0.8)" 
          zIndex={10} 
        >
          <Loader />
        </Box>
      )}

      {/* Iframe element */}
      <iframe
        id="streaming-avatar-1"
        title="myStreamingAvatarIframe"
        src="https://app.askmeai.co/chats/streaming-avatar/click-up"
        width="100%"
        height="100%"
        style={{ borderRadius: '5px', border: 'none' }}
        onLoad={() => setLoading(false)} 
        sandbox="allow-scripts allow-same-origin" 
      />
    </Box>
  );
};

export default StreamingAvatar;
