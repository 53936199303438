import React from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery } from "store/api/user";

const SidebarBrand =() => {
  const textColor = useColorModeValue("gray.700", "white.300");
  const adminDetails = useSelector((state) => state.user.userDetails);
  const { data } = useGetAllUsersQuery();
  const matchedUser = adminDetails?.user?.id && data?.data 
  ? data.data.find((user) => user.id === adminDetails.user.id) 
  : null;
  return (
    <Flex align="center" direction="column">
      <Flex textAlign='center' align="center" direction="column" gap={2} my={6}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
        Avatars AskmeAI
        </Text>
        <Text fontSize="md" color={textColor} fontWeight="bold">
        {matchedUser?.email}
        </Text>
      </Flex>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
