import {
  Box,
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Loader from "components/loader/Loader";
import React, { useState } from "react";
import { useGetAvatarsQuery } from "store/api/heygen";
import VideoPlayer from "views/admin/dashboard/components/VideoPlayer";

const AssignedVideo = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const tableBg = useColorModeValue("#fff", "#434343");
  const { data, isLoading } = useGetAvatarsQuery();
  const avatars = data?.data?.slice(0, 5) || [];
  const avatarsWithRefs = avatars?.map((avatar) => ({
    ...avatar,
    ref: React.createRef(),
  }));
  const handlePlay = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      // Pause the currently playing video when a new video starts
      document.getElementById(`video-${playingVideo}`).pause();
      document.getElementById(`video-${playingVideo}`).currentTime = 0;
    }
    setPlayingVideo(index);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Loader />
      </Box>
    );
  }
  if (!avatarsWithRefs || avatarsWithRefs.length === 0) {
    return (
        <Box p={5} bg={tableBg} borderRadius={15} textAlign="start">
            <Heading as="h2" size="lg" mb={4}>Recently Assigned Videos</Heading>
            <Box textAlign='start'>No data available</Box>
        </Box>
    );
}

  return (
    <Card p={5} bg={tableBg} borderRadius={15}>
      <Heading as="h2" size="lg" mb={4}>
        Recently Assigned Videos
      </Heading>
      <CardBody p={0}>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, "2xl": 2 }} gap={3}>
          {avatarsWithRefs?.map((avatar, index) => (
            <Box key={index}>
              <VideoPlayer
                videoSrc={avatar.url}
                onPlay={() => handlePlay(index)}
                videoId={`video-${index}`}
                userName={avatar?.name || "Unknown Duration"}
                uploader={avatar.name || "Unknown Uploader"}
              />
            </Box>
          ))}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default AssignedVideo;
