import React, { useEffect } from "react";
import "assets/css/App.css";
import "assets/css/Loader.css";
import { BrowserRouter } from "react-router-dom";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import Routes from "routes/routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "store";
import {
  CSSReset,
  ColorModeProvider,
  ChakraProvider,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import lightModeImage from "assets/img/layout/background-light-theme.png";
import darkModeImage from "assets/img/layout/background-dark-theme.jpg";

const UpdateHtmlBackground = () => {
  const { colorMode } = useColorMode();
  const backgroundImage = useColorModeValue(lightModeImage, darkModeImage);

  useEffect(() => {
    // Update the background image of the <html> element when the theme changes
    document.documentElement.style.backgroundImage = `url(${backgroundImage})`;
    document.documentElement.style.backgroundSize = "cover";
    document.documentElement.style.backgroundRepeat = "no-repeat";
    document.documentElement.style.backgroundAttachment = "fixed";
  }, [colorMode, backgroundImage]);

  return null; // This component doesn't render anything visible
};

const App = () => {
  return (
    <ChakraProvider theme={theme} cssVarsRoot="body">
      <React.StrictMode>
        <ColorModeProvider>
          <CSSReset />
          <ThemeEditorProvider>
            <UpdateHtmlBackground />
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </PersistGate>
            </Provider>
          </ThemeEditorProvider>
        </ColorModeProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
};

export default App;
