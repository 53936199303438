import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Users", "VerificationVideo"],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => `/admin/users`,
      providesTags: ["Users"],
    }),
    getVerificationVideo: builder.query({
      query: () => ({
        url: `/users/any-id-here`,
      }),
      providesTags: ["VerificationVideo"],
    }),
    updateVerificationVideo: builder.mutation({
      query: ({ body }) => ({
        url: `/users/any-id-here`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["VerificationVideo"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetVerificationVideoQuery,
  useUpdateVerificationVideoMutation,
} = userApi;
