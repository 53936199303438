import { Text } from "@chakra-ui/react";
import React from "react";

const TrucateCell = ({ text = "", length, formatter }) => {
  const displayText = formatter ? formatter(text) : text || "";
  const truncatedText =
    displayText.length > length
      ? `${displayText.slice(0, length)}...`
      : displayText;

  return <Text>{truncatedText}</Text>;
};

export default TrucateCell;
