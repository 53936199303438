import { Td, Tooltip, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const TruncateCell = ({ text = "", length, formatter }) => {
  const displayText = formatter ? formatter(text) : text || "";
  const truncatedText =
    displayText.length > length
      ? `${displayText.slice(0, length)}...`
      : displayText;
  const tooltipBg = useColorModeValue("gray.300", "#434343");
  const tooltipColor = useColorModeValue("#000", "#fff");
  return (
    <Td px="20px" border="none" fontSize={15}>
      <Tooltip
        label={displayText}
        placement="top"
        bg={tooltipBg}
        color={tooltipColor}
      >
        <span>{truncatedText}</span>
      </Tooltip>
    </Td>
  );
};

export default TruncateCell;
