import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdDashboard, MdStream, MdOutlinePendingActions } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";
import { FaBuildingColumns } from "react-icons/fa6";
import { FaCloudDownloadAlt, FaVideo } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import { AiFillAudio } from "react-icons/ai";
import { MdVideoLibrary, MdRecordVoiceOver } from "react-icons/md";

export const urls = {
  signIn: "/sign-in",
  signUp: "/sign-up",
  dashboard: "/",
  downloads: "/downloads",
  audios: "/audios",
  videos: "/videos",
  streamingAvatar: "/streaming-avatar",
  requestAvatar: "/request-avatar",
  requestAvatarDetails: (avatarType) => `/request-avatar/${avatarType}`,
  submittedAvatars: "/submitted-avatars",
  submittedAvatarDetails: (avatarId) => `/submitted-avatars/${avatarId}`,
  users: "/users",
  companies: "/companies",
  heygenVoices: "/heygen-voices",
  heygenVideos: "/heygen-videos",
  verificationVideo: "/verification-video"
};

export const sidebarLinks = [
  {
    name: "Dashboard",
    path: urls.dashboard,
    icon: <Icon as={MdDashboard} width="25px" height="25px" color="inherit" />,
  },
  {
    name: "Downloads",
    path: urls.downloads,
    icon: (
      <Icon
        as={FaCloudDownloadAlt}
        width="25px"
        height="25px"
        color="inherit"
      />
    ),
    secondary: true,
  },
  {
    name: "Audios",
    path: urls.audios,
    icon: <Icon as={AiFillAudio} width="25px" height="25px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Videos",
    path: urls.videos,
    icon: (
      <Icon as={MdVideoLibrary} width="25px" height="25px" color="inherit" />
    ),
    secondary: true,
  },
  {
    name: "Streaming Avatar",
    path: urls.streamingAvatar,
    icon: <Icon as={MdStream} width="25px" height="25px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Request Avatar",
    path: urls.requestAvatar,
    icon: <Icon as={RxAvatar} width="25px" height="25px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Submitted Avatars",
    path: urls.submittedAvatars,
    icon: (
      <Icon
        as={MdOutlinePendingActions}
        width="25px"
        height="25px"
        color="inherit"
      />
    ),
    secondary: true,
  },
  {
    name: "Companies",
    path: urls.companies,
    icon: (
      <Icon as={FaBuildingColumns} width="25px" height="25px" color="inherit" />
    ),
    secondary: true,
  },
  {
    name: "Users",
    path: urls.users,
    icon: <Icon as={HiUsers} width="25px" height="25px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Heygen Voices",
    path: urls.heygenVoices,
    icon: (
      <Icon as={MdRecordVoiceOver} width="25px" height="25px" color="inherit" />
    ),
    secondary: true,
  },
  {
    name: "Heygen Videos",
    path: urls.heygenVideos,
    icon: <Icon as={FaVideo} width="25px" height="25px" color="inherit" />,
    secondary: true,
  },
  {
    name: "Verification Video",
    path: urls.verificationVideo,
    icon: (
      <Icon as={MdVideoLibrary} width="25px" height="25px" color="inherit" />
    ),
    secondary: true,
  }
];
