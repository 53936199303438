import {
  Button,
  Flex,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Box,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Upload from "components/upload/Upload";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetAllFilesQuery } from "store/api/avatar";
import { useDeleteFileMutation } from "store/api/avatar";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

const Videos = () => {
  const toast = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryParams = {
    type: "video",
  };

  const {
    items: videosData,
    ref,
    isLoading,
    isFetching,
    hasMore,
  } = useInfiniteScroll(useGetAllFilesQuery, queryParams);

  const [deleteFile] = useDeleteFileMutation();

  const handleDelete = async (id) => {
    try {
      await deleteFile({ id }).unwrap(); // .unwrap() to handle promise rejections
      toast({
        title: "Video deleted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to delete video:",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const menuOptions = [
    { id: 1, title: "Delete", method: handleDelete, color: "red" },
    { id: 2, title: "Edit", method: () => {} },
  ];

  const modalBackground = useColorModeValue(
    "lightModeBg.100",
    "darkModeBg.100"
  );

  return (
    <Flex direction="column">
      <Button
        variant="brand"
        marginLeft="auto"
        fontWeight="500"
        mb={5}
        px={5}
        onClick={() => setIsModalOpen(true)}
      >
        Add new
      </Button>

      {!isLoading ? (
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          spacing={{ base: "40px", xl: "40px" }}
        >
          {videosData?.map((video) => (
            <VideoCard
              video={video}
              key={video?.id}
              menuOptions={menuOptions}
              showMenu={true}
              showName
            />
          ))}
        </SimpleGrid>
      ) : (
        <Box height="50vh" w="100%">
          <Loader />
        </Box>
      )}
      {isFetching && <Loader />}
      {hasMore && !isLoading && <Box ref={ref} style={{ height: "20px" }} />}
      <Modal
        variant="brand"
        size="xl"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent
          minW={{ sm: "95vw", xl: "50vw" }}
          bg={modalBackground}
          borderRadius={15}
        >
          <ModalHeader pb={0}>Add new video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Upload
              setIsModalOpen={setIsModalOpen}
              description="MP4, AVI, MOV, MKV, and WEBM files are allowed"
              accept="video"
              w="100%"
              h="300px"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Videos;
