import { useSelector } from "react-redux";
import { roles } from "routes/roles";

export const useRole = (routes) => {
  const { userRole } = useSelector((state) => state.user);

  const roleRoutes = routes.filter(
    (router) => !roles[userRole].notAllowedRoutes.includes(router.path)
  );
  return {
    roleRoutes,
  };
};
