import React from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import Information from "components/Information/Information";
import FileMenu from "components/menu/FileMenu";

const AudioCard = ({ key, voice, menuOptions }) => {
  const cardBackground = useColorModeValue("lightModeBg.100", "darkModeBg.100");
  return (
    <Card bg={cardBackground} py={2} minHeight="200px" key={key}>
      <Flex h="100%" direction="column" justifyContent="center">
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <Text fontSize={15} fontWeight={700}>{voice?.display_name ?? voice?.name}</Text>
          <FileMenu menuOptions={menuOptions} id={voice?.id} />
        </Flex>
        <audio controls style={{ width: "100%" }}>
          <source src={voice?.url ?? voice?.preview?.movio} />
        </audio>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Information
            bg={""}
            title="Language"
            value={voice?.language ?? "English"}
          />
          <Information bg={""} title="Gender" value={voice?.gender ?? "Male"} />
        </Flex>
      </Flex>
    </Card>
  );
};

export default AudioCard;
