import { Box, useDisclosure, useColorModeValue } from "@chakra-ui/react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Navbar from "components/navbar/AdminNavbar";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import { useRole } from "hooks/useRole";
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { sidebarLinks as routes } from "routes/urls";

const AdminLayout = (props) => {
  const { roleRoutes } = useRole(routes);
  const themeBgColor = useColorModeValue("#F5F5F540", "#43434340");
  let navbarBackdrop = "blur(30px)";
  let navbarFilter = "none";
  const { ...rest } = props;
  const location = useLocation();
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getActiveRoute = (routes) => {
    let activeRoute = "Default";
    let path = location.pathname;
    if(path.slice(0,6) === '/users'){
      activeRoute = "User Details"
    }else{
      activeRoute = "Default";
    }

    for (let i = 0; i < routes.length; i++) {
      if (
        path === routes[i].path ||
        routes[i].path.includes(path.slice(0, 10))
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].messageNavbar;
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  document.documentElement.dir = "ltr";
  return (
    <Box>
      <Box maxWidth='1700px' mx='auto'>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={roleRoutes} display="none" {...rest} />
          <Box
            borderTopRightRadius="20px"
            borderBottomRightRadius="20px"
            float="right"
            height="90vh"
            overflowY="scroll"
            overflow="auto"
            position="relative"
            w={{ base: "100%", xl: "calc( 100% - 300px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
            bgColor={themeBgColor}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            style={{scrollbarWidth:'none'}}
          >
            <Box
              mx="auto"
              bg={themeBgColor}
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="90vh"
            >
              <Navbar
                onOpen={onOpen}
                logoText={"Askme AI"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
              <Box pt={{ base: "30px" }} width="100%" height="100%">
                <Outlet />
              </Box>
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
      <FixedPlugin />
    </Box>
  );
};

export default AdminLayout;
