import React, { useState } from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import FileMenu from "components/menu/FileMenu";

const VideoCard = ({ video, key, menuOptions, showMenu = false, showName }) => {
  const textColor = useColorModeValue("black.900", "white.300");
  const [showControls, setShowControls] = useState(false);
  const videoBg = useColorModeValue("lightModeBg.200", "darkModeBg.200");

  return (
    <Card bg={""} minHeight="200px" p={0} key={key}>
      <Flex
        h="100%"
        direction="column"
        justifyContent="center"
        gap={showName ? 3 : 0}
      >
        <Box
          position="relative"
          width="100%"
          height="400px"
          borderRadius="20px"
          background={videoBg}
        >
          <video
            poster={video?.preview_url ?? video?.preview_image_url}
            style={{
              width: "100%",
              height: "100%",
              aspectRatio:3/2,
              objectFit: "contain",
            }}
            controls={showControls}
            onMouseOver={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
          >
            <source src={video?.url ?? video?.preview_video_url} />
          </video>
        </Box>
        {showName && (
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="md" color={textColor} fontWeight="normal">
              {video?.name ?? video?.avatar_name}
            </Text>
            {video?.is_processed && (
              <Text fontSize="md" color={textColor} fontWeight="bold">
                Processed
              </Text>
            )}
          </Flex>
        )}
      </Flex>
      {showMenu && (
        <Box ml="auto">
          <FileMenu menuOptions={menuOptions} id={video?.id} />
        </Box>
      )}
    </Card>
  );
};

export default VideoCard;
