import {
  Link,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";

const RowItem = ({
  children,
  value,
  shouldCopy,
  isLink,
  haveTooltip,
  linkDisplayText,
  preWrap,
}) => {
  const toast = useToast();
  const textColor = useColorModeValue("#000", "#fff");
  const toolTipBg = useColorModeValue("white", "#434343");

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: "Copied",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <Text
      fontSize="sm"
      fontWeight="700"
      textOverflow="ellipsis"
      overflow="hidden"
      width="100%"
      cursor={shouldCopy && "pointer"}
      position="relative"
      textTransform="capitalize"
      whiteSpace={preWrap ? "pre-wrap" : "nowrap"}
    >
      <Tooltip
        label={value}
        placement="right"
        bg={toolTipBg}
        color={textColor}
        isDisabled={!haveTooltip}
      >
        <span onClick={() => shouldCopy && copyToClipboard(value)}>
          {isLink ? (
            <Link href={value} isExternal color="blue.400">
              {linkDisplayText ?? "Link"}
            </Link>
          ) : (
            children
          )}
        </span>
      </Tooltip>
    </Text>
  );
};

export default RowItem;
