import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getAllCompanies: builder.query({
      query: () => `/admin/companies`,
      providesTags: ["Company"],
    }),
    createCompany: builder.mutation({
      query: ({ data }) => ({
        url: `/admin/companies`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Company"],
    }),
  }),
});

export const { useGetAllCompaniesQuery, useCreateCompanyMutation } = companyApi;
