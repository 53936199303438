import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  audioCount: 0,
  videoCount: 0,
};

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    saveAudioCount: (state, action) => {
      state.audioCount = action.payload;
    },
    saveVideoCount: (state, action) => {
      state.videoCount = action.payload;
    },
  },
});

export const { saveAudioCount, saveVideoCount } = avatarSlice.actions;

export default avatarSlice.reducer;
