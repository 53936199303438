import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import VideoCard from "components/card/VideoCard";
import Loader from "components/loader/Loader";
import { useGetDownloadsFilesQuery } from "store/api/avatar";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

const Downloads = () => {
  const {
    items: data,
    ref,
    isLoading,
    isFetching,
    hasMore,
  } = useInfiniteScroll(useGetDownloadsFilesQuery);

  return (
    <Flex direction="column">
      {!isLoading ? (
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          spacing={{ base: "40px", xl: "40px" }}
        >
          {data?.map((video) => (
            <VideoCard video={video} key={video?.id} showName />
          ))}
        </SimpleGrid>
      ) : (
        <Box height="60vh" w="100%">
          <Loader />
        </Box>
      )}
      {isFetching && <Loader />}
      {hasMore && !isLoading && <Box ref={ref} style={{ height: "20px" }} />}
    </Flex>
  );
};

export default Downloads;
