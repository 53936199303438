import { urls } from "routes/urls";

export const roles = {
  admin: {
    notAllowedRoutes: [
      urls.streamingAvatar,
      urls.requestAvatar,
      urls.verificationVideo,
    ],
  },
  user: {
    notAllowedRoutes: [
      urls.users,
      urls.companies,
      urls.heygenVoices,
      urls.heygenVideos,
    ],
  },
};
