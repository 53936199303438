import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import avatar1 from "assets/img/avatar/avatar1.png";
import avatar2 from "assets/img/avatar/avatar2.png";
import avatar3 from "assets/img/avatar/avatar3.png";
import AvatarCard from "components/card/AvatarCard";

const RequestAvatar = () => {
  const menuOptions = [
    { id: 1, title: "Delete", method: () => {} },
    { id: 2, title: "Edit", method: () => {} },
  ];

  const avatars = [
    {
      id: 1,
      title: "Static Avatar",
      key: "static",
      image: avatar1,
    },
    {
      id: 2,
      title: "Moving Avatar",
      key: "moving",
      image: avatar2,
    },
    {
      id: 3,
      title: "Streaming Avatar",
      key: "streaming",
      image: avatar3,
    },
  ];

  return (
    <Card bg={""} maxHeight="200px" p={0}>
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
        spacing={{ base: "40px", xl: "40px" }}
      >
        <Flex direction="column" gap={3}>
          <Text fontSize="2xl" fontWeight="bold">
            Select your avatar style
          </Text>
          <Flex direction="column" pr={100}>
            <Text fontSize="lg" fontWeight="bold">
              Static Avatar
            </Text>
            <Text fontSize="lg">
              Description: Create a professional stationary avatar for your
              profile or presentations.
            </Text>
          </Flex>
          <Flex direction="column" pr={100}>
            <Text fontSize="lg" fontWeight="bold">
              Moving Avatar
            </Text>
            <Text fontSize="lg">
              Description: Bring your avatar to life with subtle animations,
              walking and gestures.
            </Text>
          </Flex>
          <Flex direction="column" pr={100}>
            <Text fontSize="lg" fontWeight="bold">
              Streaming Avatar
            </Text>
            <Text fontSize="lg">
              Description: Enable real-time interaction with a fully animated
              version of yourself.
            </Text>
          </Flex>
        </Flex>
        {avatars?.map((avatar) => (
          <AvatarCard
            avatar={avatar}
            menuOptions={menuOptions}
            height={"340px"}
          />
        ))}
      </SimpleGrid>
    </Card>
  );
};

export default RequestAvatar;
