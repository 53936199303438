import { getAuthToken } from "utils/auth";

// eslint-disable-next-line import/prefer-default-export
export const baseQuery = {
  baseUrl: `${process.env.REACT_APP_BE_URL}/api`,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
};

// Base query for heygen APIs
export const baseQueryHeygen = {
  baseUrl: "https://api.heygen.com",
  prepareHeaders: (headers) => {
    headers.set("X-Api-Key", `${process.env.REACT_APP_HEYGEN_API_KEY}`);
    return headers;
  },
};
